import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage = () => (
  <Layout>
    <SEO title="Privacy Policy and Terms of Use" />
    <section className="legal">
      <div className="center">
        <h1>Privacy and Terms of Use</h1>
        <h2>By using butlerfootandankle.com, you're agreeing to the following.</h2>
        <hr />
      </div>
      <h2>Privacy Policy</h2>
      <p>This website uses Google
          Analytics to track anonymous user behavior. You can find the Google Analytics <a
              href="https://www.google.com/intl/en/analytics/terms/us.html" target="_blank" rel="noreferrer">terms here</a> and <a
              href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">privacy policy here</a>. 
        W. Murray Butler, DPM (WMB) reserves the right to change this privacy policy at any time.</p>
      <h2>Terms of Use</h2>
      <p>WMB owns or licenses everything on this site and provides it to you for your information and non-commercial
          use only. You may not use it for any other purpose without our prior written consent. WMB reserves all rights
          not expressly granted to you. And you agree not to circumvent, disable, or otherwise interfere with
          security-related features of the site.</p>
      <p>The materials on this website are provided “as is,” and make no warranties, expressed or implied, and
          hereby disclaim and negate all other warranties, including without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights. Further, WMB doesn’t warrant or make any representations concerning
          the accuracy, likely results, or reliability of the use of the materials on this website or otherwise
          relating to such materials or on any sites linked to this site.</p>
      <p>In no event shall WMB be liable for any damages — including, without limitation, damages for loss of data
          or profit, or due to business interruption — arising out of the use or inability to use the materials on
          our website, even if notified orally or in writing of the possibility of such damage.</p>
      <p>Because some jurisdictions do not allow limitations on implied warranties,
          or limitations of liability for consequential or incidental damages, these limitations may not apply to
          you.</p>
    </section>
  </Layout>
)

export default LegalPage
